/* General styles */
body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .pro-sidebar-layout {
    display: flex;
    flex-direction: row;
  }

  /* .pro-menu.haped.square.inner-submenu-arrows {
    height: 1000px;
  }
   */
  .sidebar {
    height: auto; /* Adjusts to content height */
    width: 250px;
    background-color: #333;
    color: white;
    overflow: auto;
    transition: width 0.3s ease;
  }
  
  .sidebar ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .sidebar ul li {
    padding: 15px;
    text-align: center;
    border-bottom: 1px solid #444;
  }
  
  .sidebar ul li a {
    color: white;
    text-decoration: none;
    display: block;
  }
  
  .sidebar ul li a:hover {
    background-color: #575757;
  }
  
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .pro-sidebar-layout {
      flex-direction: column;
    }
  
    .sidebar {
      width: 100%; /* Full width on smaller screens */
    }
  
  }
  
  @media (max-width: 480px) {
    .sidebar ul {
      display: flex;
      flex-wrap: wrap;
    }
  
    .sidebar ul li {
      flex: 1 1 50%; /* Two items per row */
      text-align: center;
      padding: 10px;
    }
  
  }
  