
  .link-style {
    color: #fafafa !important; /* Ensure the text color is white */
    text-decoration: none; /* Remove underline */
  }
  
  .btn-reset {
    background-color: #e2e2e2; /* Corrected background color */
    color: #141414; /* Corrected text color */
    border-color: #1976d2; /* Corrected border color */
  }
  