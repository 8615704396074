@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  /* height: 100%; */
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  overflow-x: hidden !important;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* 
.pro-sidebar {
  width: 290px !important;
} */
/* .table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #1f2a40 !important;
}

thead th {
  background: #3e4396 !important;
} */

tbody,
td,
tfoot,
th,
thead,
tr {
  vertical-align: baseline;
}


/* light mode */
.pro-sidebar {
  color: #141414 !important;
}

.css-1f5e6hx .pro-inner-item:hover {
  color: #6870fa !important;
}

.css-1f5e6hx .pro-menu-item.active {
  color: #6870fa !important;
}

.pro-sidebar:focus {
  color: #6870fa !important;
}

.pro-sidebar .pro-menu a {
  text-decoration: none;
  color: #141414 !important;
}

.pro-sidebar .pro-menu a:hover {
  text-decoration: none;
  color: #6870fa !important;
}

.pro-sidebar .pro-menu a:focus {
  text-decoration: none;
  color: #6870fa !important;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:focus {
  outline: none;
  color: #141414 !important;
}

/* .css-59jqr3-MuiSvgIcon-root {
  color: #5ca68a !important;
} */

.css-1npvcx3-MuiTypography-root {
  color: #141414 !important;
}

.css-1wobld1-MuiTypography-root {
  color: #141414 !important;
}

.table-dark {
  --bs-table-color: #141414;
  --bs-table-bg: white !important;
}

thead th {
  background: #4cceac !important;
  --bs-table-color: #fff;
}

.css-y1sst-MuiButtonBase-root-MuiButton-root {
  background-color: #4cceac !important;
}

.pro-sidebar {
  background: #e7f4e7 !important;
}

.css-sekg12 {
  background-color: #e7f4e7 !important;
}

.css-1q4qvzg {
  background-color: #e7f4e7 !important;
}

.css-mqmzkw {
  background-color: #e7f4e7 !important;
}

.css-1pm2176 {
  background-color: #e7f4e7 !important;
}

.css-4432z8 {
  background-color: #e7f4e7 !important;
}

.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary.Mui-error.MuiInputBase-fullWidth.MuiInputBase-formControl.css-1tjkjbo-MuiInputBase-root-MuiFilledInput-root {
  background: #e7f4e7 !important;
}

.css-1tjkjbo-MuiInputBase-root-MuiFilledInput-root {
  background: #e7f4e7 !important;
}

label[data-shrink=false]+.MuiInputBase-formControl .css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input {
  background: #e7f4e7 !important;
}

.css-1sc254o-MuiInputBase-root-MuiFilledInput-root {
  background: #e7f4e7 !important;
}

.css-jtrkzm .pro-sidebar-inner {
  background: #e7f4e7 !important;
}