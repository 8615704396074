.landing-page {
    display: flex;
    flex-direction: column;
    height: 120vh; /* Ensure full viewport height */
  }
  
  .card-container {
    flex: 1; /* Let the card (form) take available space */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px; /* Adjust this to set space between the header and form */
  }
  
  .card {
    background-color: #e7f4e7;
    padding: 2rem;
    width: 50%;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  footer {
    background-color: #e7f4e7;
    padding: 20px 0;
  }
  
  footer .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  footer .d-flex {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  footer .text-muted {
    color: #888;
    font-size: 0.9rem;
  }
  
  footer p {
    margin: 0;
  }
  