/* Login.css */

/* Basic structure of the page */
.landing-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  /* Navbar styling */
  .navbar {
    background-color: #e7f4e7;
  }
  
  /* Card (Login Form) */
  .card {
    background-color: #e7f4e7;
    padding: 2rem;
    max-width: 500px;
    width: 100%;
    margin: auto;
  }
  
  .card h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  /* Footer */
  .footer {
    background-color: #e7f4e7;
    padding: 20px;
    text-align: center;
  }
  
  .footer a {
    color: #6c757d;
    margin: 0 10px;
    text-decoration: none;
  }
  
  .footer a:hover {
    color: #28a745;
  }
  